import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import './styles.scss'

const MenuItem = ({ title, url, onClick = {}, localClass }) => (
  <Link className="menu-item" to={url} onClick={onClick}>
    <p className={localClass}>{title}</p>
    <div className="chevron-icon" />
  </Link>
)

MenuItem.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
}

MenuItem.defaultProps = {
  title: ``,
  url: `#`
}

export default MenuItem
