import React from 'react'
const RedLine = ({ containerStyles = {}, styles = {} }) => (
  <div
    className="red-line-container"
    style={{
      width: '100%',
      height: 25,
      display: 'flex',
      justifyContent: 'flex-end',
      ...containerStyles
    }}
  >
    <div
      className="child"
      style={{
        backgroundColor: '#ED1B2E',
        width: '45%',
        height: '100%',
        ...styles
      }}
    ></div>
  </div>
)

export default RedLine
