import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import './styles.scss'

import MenuItem from '../MenuItem'
import Button from '../Button'

import prudential_logo from '../../images/prumdrt2020/logo-header.svg'
import earth_icon from '../../images/prumdrt2020/earth.svg'

const Header = ({ locale = 'en', data, isRunPage }) => {
  const [open, setOpen] = useState(false)
  const [showLanguageMenu, setShowLanguageMenu] = useState(false)
  const languageMenuRef = useRef(null)
  const languageMobileMenuRef = useRef(null)
  const menuRef = useRef(null)

  const menu_locale = locale === 'en' ? '' : locale
  const MENU = [
    {
      title: data.home,
      url: `/${menu_locale}`
    },
    {
      title: data.agenda,
      url: `/${menu_locale}#agenda`
    },
    {
      title: data.speakers,
      url: menu_locale.length > 0 ? `/${menu_locale}/speakers` : `/speakers`
    },
    {
      title: data.virtual_run,
      url: menu_locale.length > 0 ? `/${menu_locale}/virtualrun` : `/virtualrun`
    },
    {
      title: data.leaderboard,
      url:
        menu_locale.length > 0 ? `/${menu_locale}/leaderboard` : `/leaderboard`
    },
    {
      title: data.virtual_activities,
      url:
        menu_locale.length > 0
          ? `/${menu_locale}/virtualactivities`
          : `/virtualactivities`
    },
    {
      title: data.social_media,
      url:
        menu_locale.length > 0
          ? `/${menu_locale}/social_media`
          : `/social_media`
    },
    {
      title: data.video_gallery,
      url:
        menu_locale.length > 0
          ? `/${menu_locale}/video_gallery`
          : `/video_gallery`
    },
    {
      title: data.contact_us,
      url: `/${menu_locale}#contact`
    }
  ]

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const toggleMenu = event => {
    setOpen(!open)
  }

  const handleClickOutside = event => {
    if (
      languageMenuRef.current &&
      !languageMenuRef.current.contains(event.target) &&
      !languageMobileMenuRef.current.contains(event.target)
    ) {
      setShowLanguageMenu(false)
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  const registerNowButton = () => (
    <Button
      title={data.register_now.toUpperCase()}
      style={{ backgroundColor: `#DE1B2E` }}
      titleStyle={{
        fontFamily: 'FSAlbert-BoldItalic',
        fontWeight: 700,
        fontSize: 20,
        color: 'white'
      }}
      onClick={() =>
        window.open(`https://prumdrt2020.com/register/${locale}`, '_blank')
      }
    />
  )
  const languageButton = (isMobile = false) => {
    const locales = [
      {
        code: 'en',
        name: 'English'
      },
      {
        code: 'cn',
        name: '中文简体'
      },
      {
        code: 'zh',
        name: '中文繁體'
      },
      {
        code: 'vn',
        name: 'Vietnamese'
      },
      {
        code: 'id',
        name: 'Bahasa Indonesia'
      }
    ]

    const language = locales.find(item => item.code === locale)

    return (
      <div
        className="language-button-wrapper"
        ref={isMobile ? languageMobileMenuRef : languageMenuRef}
      >
        <Button
          style={{ backgroundColor: '#DDDDDD' }}
          onClick={() => setShowLanguageMenu(!showLanguageMenu)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ width: 24, height: 24 }} src={earth_icon} />
            <p
              style={{
                fontFamily: 'FSAlbert',
                fontSize: 19,
                color: '#707173',
                marginLeft: 12
              }}
            >
              {language.name || 'English'}
            </p>
          </div>
        </Button>
        {showLanguageMenu && (
          <div className="country-dropdown-menu">
            {locales.map((item, index) => (
              <Link
                to={`/${item.code === 'en' ? '' : item.code}`}
                key={index}
                className="country-menu-item"
              >
                {item.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <header className="header">
      <div className="header-container">
        <Link className="logo" to={`/${menu_locale}`}>
          <img src={prudential_logo} />
        </Link>
        <div className="header-button-group">
          {/* {registerNowButton()} */}
          {languageButton()}
        </div>
        <div className="menu-wrapper">
          <div className="menu-group">
            {MENU.map((item, index) => (
              <MenuItem
                key={index}
                title={item.title}
                url={item.url}
                localClass={locale}
              />
            ))}
          </div>

          <div className="menu-mobile" ref={menuRef}>
            <div
              className={`hamburger ${open ? 'open' : ''}`}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={`menu ${open ? 'open' : ''}`}>
              <div className="header-button-group-mobile">
                {/* {registerNowButton()} */}
                {languageButton(true)}
              </div>
              {MENU.map((item, index) => (
                <MenuItem
                  key={index}
                  title={item.title}
                  url={item.url}
                  onClick={() => setOpen(false)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
