import React from 'react'
import MenuItemFooter from '../MenuItemFooter'
import logoFooter from '../../images/prumdrt2020/logo.png'
import './styles.scss'

const Footer = ({ locale = 'en', data, customClass }) => {
  const menu_locale = locale === 'en' ? '' : locale
  const menuFooter1 = [
    {
      title: data.home,
      url: `/${menu_locale}`
    },
    {
      title: data.agenda,
      url: `/${menu_locale}#agenda`
    },
    {
      title: data.speakers,
      url: menu_locale.length > 0 ? `/${menu_locale}/speakers` : `/speakers`
    },
    {
      title: data.virtual_run,
      url: menu_locale.length > 0 ? `/${menu_locale}/virtualrun` : `/virtualrun`
    },
    {
      title: data.leaderboard,
      url:
        menu_locale.length > 0 ? `/${menu_locale}/leaderboard` : `/leaderboard`
    },
    {
      title: data.virtual_activities,
      url:
        menu_locale.length > 0
          ? `/${menu_locale}/virtualactivities`
          : `/virtualactivities`
    },
    {
      title: data.social_media,
      url:
        menu_locale.length > 0
          ? `/${menu_locale}/social_media`
          : `/social_media`
    },
    {
      title: data.video_gallery,
      url:
        menu_locale.length > 0
          ? `/${menu_locale}/video_gallery`
          : `/video_gallery`
    },
    {
      title: data.contact_us,
      url: `/${menu_locale}#contact`
    }
  ]

  return (
    <div className={`footer ${customClass}`}>
      <div
        className="container"
        style={{ backgroundImage: `url(${data.footer_background})` }}
      >
        <div className="top-footer">
          <div className="img-footer-wrapper">
            <div className="img-footer">
              <img src={logoFooter} className="" />
            </div>
            <div className="red-line"></div>
          </div>

          <div className="group-menu-footer">
            {menuFooter1.map((item, index) => (
              <MenuItemFooter
                key={index}
                title={item.title}
                url={item.url}
                locale={locale}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="bottom-footer container">
        <p className={`title-privacy-policy ${locale}`}>
          {data.privacy_policy}
        </p>
        <p className={`content-privacy-policy ${locale}`}>
          {data.privacy_policy_content}
        </p>
        <p className={`copy-right ${locale}`}>{data.footer}</p>
      </div>
    </div>
  )
}

export default Footer
