import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import './styles.scss'

const MenuItemFooter = ({ title, url, locale }) => (
  <Link className={`wrap-item ${locale}`} to={url}>
    {title} <span className="chevron-right"> ></span>
  </Link>
)

MenuItemFooter.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
}

MenuItemFooter.defaultProps = {
  title: ``,
  url: `#`
}

export default MenuItemFooter
