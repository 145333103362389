/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, withPrefix } from 'gatsby'
import Helmet from 'react-helmet'
import Header from '../Header'
import Footer from '../Footer'
import './fonts.scss'
import './styles.scss'
import chevron_up from '../../images/prumdrt2020/chevron_up.svg'
import RedLine from 'src/components/RedLine'
import parse from 'html-react-parser'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true
  })
}

const Layout = ({
  children,
  customFooterClass,
  locale,
  isRunPage = false,
  hasContactSection = true,
  extraClassNameWrapper = '',
  extraMainClassName = ''
}) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  useEffect(() => {
    document.addEventListener('scroll', handleScroll, false)
    return () => {
      document.removeEventListener('scroll', handleScroll, false)
    }
  }, [])

  const handleScroll = () => {
    const scrollY = window.scrollY

    if (scrollY > 10) {
      setShowScrollToTop(true)
    } else {
      setShowScrollToTop(false)
    }
  }

  const renderScrollToTop = () => {
    return (
      <a href="#" className="scroll-to-top-container">
        <img src={chevron_up} alt="" />
      </a>
    )
  }

  const renderContactSection = content => (
    <div
      className="contact-section"
      id="contact"
      style={{ backgroundImage: `url(${content.contact_background})` }}
    >
      <RedLine />
      <div className="contact-content">
        <div className={`contact-title ${locale}`}>{content.contact}</div>
        <div className={`contact-details ${locale}`}>
          {parse(content.contact_content)}
        </div>
      </div>
    </div>
  )

  return (
    <StaticQuery
      query={graphql`
        {
          allRestApiApiLayout {
            edges {
              node {
                locale
                content {
                  home
                  agenda
                  speakers
                  virtual_run
                  leaderboard
                  contact_us
                  register_now
                  contact
                  contact_content
                  contact_background
                  privacy_policy
                  privacy_policy_content
                  footer
                  footer_background
                  virtual_activities
                  social_media
                  video_gallery
                }
              }
            }
          }
        }
      `}
      render={data => {
        const menu = data.allRestApiApiLayout.edges.find(
          item => item.node.locale === locale
        )

        return (
          <>
            <Helmet>
              <script src={withPrefix('script.js')} type="text/javascript" />
            </Helmet>
            <Header
              siteTitle={'Prudential MDRT'}
              locale={locale}
              data={menu.node.content}
              isRunPage={isRunPage}
            />
            <div
              style={{
                margin: `0 auto`,
                maxWidth: 1920,
                padding: `0px`,
                paddingTop: 0
              }}
              className={extraClassNameWrapper}
            >
              <main className={extraMainClassName}>{children}</main>
              {showScrollToTop && renderScrollToTop()}
            </div>
            {hasContactSection && renderContactSection(menu.node.content)}
            <Footer
              customClass={customFooterClass}
              locale={locale}
              data={menu.node.content}
            />
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
