import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"

const Button = ({ title, style, titleStyle, children, onClick, active }) => (
  <div onClick={onClick} className={`button-wrapper ${active}`} style={style}>
    {children || <p style={titleStyle}>{title}</p>}
  </div>
)

Button.propTypes = {
  title: PropTypes.string,
}

Button.defaultProps = {
  title: ``,
  style: {},
  titleStyle: {},
}

export default Button
